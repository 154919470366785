const MODE = 'DEV' // 'STAGE'

const ENVIRONMENTS = {
    PROD:{       
        ENDPOINT:'',
        UPLOAD_ENDPOINT:''     
    },
    STAGE:{        
        ENDPOINT: window.location.protocol+'//'+window.location.host,
        UPLOAD_ENDPOINT: window.location.protocol+'//'+window.location.host+'/fileUpload'
    },
    DEV:{       
        ENDPOINT:window.location.protocol+'//'+window.location.host,
        UPLOAD_ENDPOINT:window.location.protocol+'//'+window.location.host + '/fileUpload'
    }
}

export const ENDPOINT = ENVIRONMENTS[MODE].ENDPOINT
export const UPLOAD_ENDPOINT = ENVIRONMENTS[MODE].UPLOAD_ENDPOINT